<template>
  <div class="Works">
    <!-- 轮播图 -->
    <van-swipe :autoplay="3000" :show-indicators="false">
      <van-swipe-item v-for="(item, index) in lunboList" :key="index">
        <img class="van-swipe-item-img" :src="item.image" />
      </van-swipe-item>
    </van-swipe>
    <div class="Works-main relative">
      <div class="Works-footer absolute"></div>

      <!-- Works -->
      <div class="Works-box w1330">
        <!-- 分类 -->
        <div class="Works-menu flex flex-wrap">
          <div
            class="Works-menu-item f26 pointer"
            :class="{ 'Works-menu-item-active': cateCurrent == index }"
            v-for="(item, index) in cateList"
            :key="index"
            @click="cateChange(index)"
          >
            {{ item.name }}
          </div>
          <div class="Works-menu-item f26 disabled">VFX</div>
        </div>
        <!-- PC端列表 -->
        <ul class="Works-list flex flex-wrap">
          <li
            class="Works-list-item pointer"
            v-for="(item, index) in list"
            :key="item.id"
            @click="preImage(index)"
          >
            <img class="list-item-cover" :src="item.image" />
            <div class="mask"></div>
          </li>
        </ul>
        <!-- 分页器 -->
        <pager @before="pageClick(-1)" @next="pageClick(1)"></pager>

        <!-- mobile端列表 -->
        <div class="Works-list-mobile flex align-center justify-between">
          <div class="Works-btn Works-left-btn" @click="mobileChange(-1)"></div>
          <div class="Works-watch-box relative">
            <div
              class="Works-watch-box-slit absolute flex"
              :style="[watchStyle]"
            >
              <img
                class="Works-watch-box-cover"
                v-for="(item, index) in list"
                :key="index"
                v-lazy="item.image"
                @click="preImageMobile(index)"
              />
            </div>
          </div>
          <div class="Works-btn Works-right-btn" @click="mobileChange(1)"></div>
        </div>
        <!-- <p class="Works-tips-mobile b800 f14 text-align">
          OVER 10 years experience,Previously worked for Virtuos
        </p> -->
      </div>
    </div>
    <!-- pc预览图片弹窗 -->
    <preImage
      :show="show"
      :list="list"
      :current="preCurrent"
      @close="show = false"
    ></preImage>
  </div>
</template>

<script>
import { mapState, mapActions } from "vuex";
import pager from "../components/pager.vue";
import preImage from "../components/preImage.vue";
import { ImagePreview } from "vant";
export default {
  name: "Works",
  data() {
    return {
      lunboList: [],
      cateList: [],
      cateCurrent: 0,
      page: 1,
      limit: 12,
      list: [],
      current: 0,
      show: false, //预览弹窗开关
      preCurrent: 0, //预览图片索引
    };
  },
  components: {
    pager,
    preImage,
  },
  computed: {
    watchStyle() {
      return {
        transform: `translateX(-${this.current * 250}px)`,
        transition: `.3s ease-in-out`,
      };
    },
  },
  created() {
    this.setNavbarCurrent(this.$route.path);
    this.getLunbo();
    this.getCateGory();
  },
  mounted() {},
  methods: {
    ...mapActions(["setNavbarCurrent"]),
    //获取背景轮播
    async getLunbo() {
      // var res = await this.$api.Get('index/advertisement?position=works&page=1&limit=99999')
      // this.lunboList = res.data
      this.lunboList = [
        {
          image: "/Works/1.webp",
          status_text: "",
          position_text: "",
        },
        {
          image: "/Works/2.webp",
          status_text: "",
          position_text: "",
        },
        {
          image: "/Works/3.webp",
          status_text: "",
          position_text: "",
        },
      ];
    },
    //获取分类
    async getCateGory() {
      var res = await this.$api.Get("index/worksCategory");
      this.cateList = res.data;
      this.getList();
    },
    //获取列表
    async getList(source) {
      const loading = this.$loading({
        lock: true,
        text: "Loading",
        background: "rgba(255, 255, 255, 0.7)",
      });
      var res = await this.$api.Get(
        `index/works?works_category_id=${
          this.cateList[this.cateCurrent].id
        }&page=${this.page}&limit=${this.limit}`
      );
      this.list = res.data;
      loading.close();
    },
    //分类切换
    cateChange(index) {
      this.cateCurrent = index;
      this.current = 0;
      this.page = 1;
      this.getList("refresh");
    },
    //轮播切换
    mobileChange(step) {
      var dis = this.current + step;
      if ((step < 0 && dis >= 0) || (step > 0 && dis < this.list.length)) {
        this.current = dis;
      }
    },
    //pc端预览图片
    preImage(index) {
      this.preCurrent = index;
      this.show = true;
    },
    //mobile端预览图片
    preImageMobile(index) {
      var images = this.list.map((item) => {
        return item.image;
      });
      ImagePreview({
        images,
        startPosition: index,
        showIndicators: true,
      });
    },
    async pageClick(step) {
      var idx = this.page + step;
      if (idx < 1) {
        this.toast("没有更多数据");
      } else {
        const loading = this.$loading({
          lock: true,
          text: "Loading",
          background: "rgba(255, 255, 255, 0.7)",
        });
        var res = await this.$api.Get(
          `index/works?works_category_id=${
            this.cateList[this.cateCurrent].id
          }&page=${idx}&limit=${this.limit}`
        );
        if (res.data.length) {
          this.page = idx;
          this.list = res.data;
          this.scroll();
        } else this.toast("没有更多数据");
        loading.close();
      }
    },
    toast(message) {
      this.$message({
        type: "warning",
        message,
        offset: 100,
      });
    },
    scroll() {
      var dis =
        document.querySelector(".Works-main").offsetTop -
        document.querySelector("#navbar").clientHeight;
      document.documentElement.scrollTop = dis;
    },
  },
};
</script>
<style lang="less" scoped>
.van-swipe-item-img {
  display: block;
  width: 100%;
  height: calc(100vh - 71px);
  object-fit: cover;
}

.Works-main {
  padding-bottom: 180px;
  min-width: 1330px;
  background: url(../assets/gray-bg.png) no-repeat bottom / 5000px 3080px;
}

.Works-footer {
  bottom: 0;
  width: 100%;
  height: 520px;
  background-color: #f5f5f7;
  z-index: -1;
}

.Works-menu {
  padding: 86px 0 109px;
}

.Works-menu-item {
  margin-top: 10px;
  padding: 18px 38px;
  background-color: #fff;
  box-shadow: 0px 9px 9px 0px rgba(6, 0, 1, 0.09);
  border-radius: 10px;
  transition: 0.3s ease;
  &.disabled {
    background-color: #ccc;
    cursor: not-allowed;
    // pointer-events: none;
  }
}

.Works-menu-item:nth-of-type(n + 2) {
  margin-left: 14px;
}

.Works-menu-item-active {
  color: #ffffff;
  background-color: #e4523e;
}

.Works-list-mobile,
.Works-tips-mobile {
  display: none;
}

.Works-list-item {
  position: relative;
  margin-right: 22px;
  width: 420px;
  height: 300px;
  border-radius: 15px;
  overflow: hidden;
}

.Works-list-item:nth-of-type(3n) {
  margin-right: 0;
}

.Works-list-item:nth-of-type(n + 4) {
  margin-top: 22px;
}

.list-item-cover {
  width: 100%;
  height: 100%;
  object-fit: cover;
  transition: 1s ease;
}

.mask {
  position: absolute;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  background: rgba(0, 0, 0, 0.5);
  display: none;
}

@keyframes _scale {
  0% {
    transform: translate(-50%, -50%) scale(0);
  }

  100% {
    transform: translate(-50%, -50%) scale(1);
  }
}

.mask::after {
  content: "";
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  width: 84px;
  height: 83px;
  background: url(../assets/icon10.png) no-repeat;
  animation: _scale 0.6s ease-in-out;
}

.Works-list-item:hover .mask {
  display: block;
}

.Works-list-item:hover .list-item-cover {
  transform: scale(1.12);
}

@media screen and (max-width: 750px) {
  .van-swipe-item-img {
    height: auto;
    height: 60vw;
  }

  .Works-main {
    padding-bottom: 1px;
    min-width: 100%;
    background-size: auto 370px;
  }

  .Works-footer {
    bottom: 0;
    width: 100%;
    height: 220px;
    background-color: #f5f5f7;
    z-index: -1;
  }

  .Works-box {
    width: 325px;
  }

  .Works-menu {
    padding: 10px 0 80px;
  }

  .Works-menu-item {
    padding: 9px 18px;
    border-radius: 5px;
    font-size: 13px;
    cursor: none;
  }

  // .Works-menu-item:nth-of-type(n + 2) {
  //     margin-left: 17px;
  // }

  .Works-list,
  .pager {
    display: none;
  }

  .Works-list-mobile,
  .Works-tips-mobile {
    display: flex;
  }

  .Works-btn {
    width: 30px;
    height: 30px;
    background: url(../assets/icon14.png) no-repeat;
    background-size: 11px;
    background-position: center;
  }

  .Works-right-btn {
    transform: rotate(180deg);
  }

  .Works-watch-box {
    width: 250px;
    height: 189px;
    border-radius: 8px;
    overflow: hidden;
  }

  .Works-watch-box-cover {
    width: 250px;
    height: 189px;
    object-fit: cover;
  }

  .Works-tips-mobile {
    margin: 30px 0;
  }
}
</style>
